<template>
    <section>
        <div id="document-validation-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="close"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Validasi Dokumen</h2>
                </div>
                <div class="uk-modal-body">
                    <p class="uk-margin-remove">Please select the validation status for <span class="uk-text-bold">{{ findDocumentName(document) }}</span></p>
                    <label for="status" class="uk-form-label">Status</label>
                    <select id="status" name="status" class="uk-select" v-model="is_valid">
                        <option :value=null>Pilih status dokumen</option>
                        <option :value=true>Valid</option>
                        <option :value=false>Tidak Valid</option>
                    </select>
                    <div v-if="is_valid === false">
                        <label for="validationNotes" class="uk-form-label">Notes <b class="uk-text-danger">*</b></label>
                        <textarea
                            id="validationNotes"
                            name="validationNotes"
                            rows="3"
                            class="uk-textarea"
                            v-model="notes"
                        ></textarea>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        :class="[
                            !is_valid && !notes ? 'uk-button-default' : 'uk-button-primary',
                            'uk-button  uk-margin-small-left uk-modal-close'
                        ]"
                        type="button"
                        @click="doValidateDocument"
                        :disabled="!is_valid && !notes"
                    >Confirm</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";

export default {
    props: {
        document: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
        additionalDocId: {
            type: String,
            required: false,
            default: '',
        },
        additionalDocName: {
            type: String,
            required: false,
            default: '',
        },
    },
    data(){
        return {
            is_valid: null,
            notes: '',
            isSubmit: false,
        };
    },
    async mounted() {
        await window.UIkit.modal('#document-validation-modal').show();
        // if (
        //     (this.additionalDocId && !this.additionalDocName) ||
        //     (!this.additionalDocId && this.additionalDocName)
        // ) {
        //     notificationDangerCustom('Failed to open modal because incomplete data');
        //     await this.close();
        // }
    },
    methods: {
        ...mapActions({
            validateDocument: 'other_document/validateDocument',
        }),
        async doValidateDocument() {
            try {
                this.isSubmit = true;
                let payload = {
                    is_valid: this.is_valid,
                    notes: this.notes
                };
                // if (this.additionalDocId && this.additionalDocName) {
                //     payload.doc_id = this.additionalDocId;
                // }
                const result = await this.validateDocument({
                    user_id: this.userId,
                    document: this.document,
                    payload: payload
                });

                if (result && result.status === 'OK') {
                    notificationSuccess(`Validation successful`);
                } else {
                    notificationDangerCustom('Failed to validate!');
                }
            } catch (error) {
                notificationDanger(error);
            }

            this.isSubmit = false;
            this.close();
        },
        findDocumentName(value) {
            let docName = '';
            if (value == 'other_document') {
                docName = this.additionalDocName;
            } else if (value.includes('ktp')) {
                docName = 'Foto KTP';
            } else if (value.includes('kk')) {
                docName = 'Foto KK';
            } else if (value.includes('ijazah')) {
                docName = 'Foto Ijazah Terakhir';
            } else if (value.includes('bank')) {
                docName = 'Foto Buku Tabungan';
            } else if (value.includes('photo')) {
                docName = 'Pas Photo';
            } else if (value.includes('sim')) {
                docName = 'Foto SIM';
            } else if (value.includes('stnk')) {
                docName = 'Foto STNK';
            } else if (value.includes('skck')) {
                docName = 'Foto SKCK';
            } else if (value.includes('bpjs_kes')) {
                docName = 'Foto Kartu BPJS Kesehatan';
            } else if (value.includes('bpjs_tk')) {
                docName = 'Foto Kartu BPJS Ketenagakerjaan';
            } else if (value.includes('cv')) {
                docName = 'CV';
            }

            return docName;
        },
        async close() {
            await window.UIkit.modal('#document-validation-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
